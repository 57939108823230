import { useEffect, useRef, useState } from "react";
import {
  Stack,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  ButtonGroup,
  useToast,
  Textarea,
  useDisclosure,
  Box
} from "@chakra-ui/react";
import {
  useTransferOperationMutation,
  useGetAssignUserLocationsQuery,
  useGetFilterProductQuery,
  useGetProductQuantityQuery
} from "../../../api";
import { Select, GroupBase } from "chakra-react-select";
import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";
import {
  DestinationLocationGroup,
  OriginLocationGroup,
  ProductGroup,
  StockOperationItems
} from "../types/stock-operation.type";
import { FaPlus, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { PageCardComponent } from "../../core/components/page-card.component";
import {
  RemoveSelectedProductFromAllProducts,
  userCurrentRole
} from "../../../utils/common-functions";
import { ConfirmAlertComponent } from "./confirm-alert.component";
import { ProductTrackingTypeOptions } from "../../../api/type";
import { OperationService } from "../../../service/operation/operation.service";

type TransferFormValues = {
  originLocation: OriginLocationGroup;
  destinationLocation: DestinationLocationGroup;
  stockOperationItems: StockOperationItems[];
  notes: string;
};

const TransferComponent = () => {
  let id = localStorage.getItem("location");
  const toast = useToast();
  const navigate = useNavigate();
  const role = userCurrentRole();
  const transferConfirmAlert = useDisclosure();
  const cancelRef = useRef();
  const getLocationQuery = useGetAssignUserLocationsQuery({});

  const [originLocationOptions, setOriginLocationOptions] = useState<
    OriginLocationGroup[]
  >([]);
  const [originLocation, setOriginLocation] = useState<OriginLocationGroup>();

  const [destinationLocationOptions, setDestinationLocationOptions] = useState<
    DestinationLocationGroup[]
  >([]);

  useEffect(() => {
    if (getLocationQuery.data?.data.locations) {
      const locationsWithValues = getLocationQuery.data?.data.locations.map(
        (location) => {
          return {
            label: location.name,
            value: location.id
          };
        }
      );
      setOriginLocationOptions(locationsWithValues);
      setDestinationLocationOptions(locationsWithValues);
    }
  }, [getLocationQuery.data]);

  useEffect(() => {
    if (originLocationOptions.length) {
      setOriginLocation(
        originLocationOptions.find((d) => d.value === Number(id))
      );
    }
  }, [id, originLocationOptions]);

  const getProductQuery = useGetFilterProductQuery({ id: Number(id) });
  const [createTransfer, resultTransfer] = useTransferOperationMutation();
  const [productOptions, setProductOptions] = useState<ProductGroup[]>([]);

  useEffect(() => {
    if (getProductQuery.data?.data.products) {
      const productWithValues = getProductQuery.data?.data.products.map(
        (product) => {
          return {
            label: product.name,
            value: product.id,
            unit: product.unit,
            type: product.type,
            quantity: product.quantity
          };
        }
      );
      setProductOptions(productWithValues);
    }
  }, [getProductQuery.data]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<TransferFormValues>({
    defaultValues: {
      stockOperationItems: [{}]
    }
  });

  const { fields, append, remove } = useFieldArray<
    TransferFormValues,
    "stockOperationItems"
  >({
    control,
    name: "stockOperationItems"
  });

  const watch = useWatch({
    control,
    name: "stockOperationItems"
  });

  const transferHandleSubmit = handleSubmit((data) => {
    const allStockOperationItems = data.stockOperationItems.map((singleItem) => {

      return {
        productId: singleItem.product.value,
        quantity: OperationService.getQuantity(singleItem.product.type as string, singleItem.quantity),
        trackingNumber: singleItem.trackingNumber
      }

    })
    createTransfer({
      data: {
        stockOperation: {
          originLocationId: Number(id),
          destinationLocationId: data.destinationLocation.value,
          stockOperationItems: allStockOperationItems,
          notes: data.notes
        }
      }
    });
  });
  if (resultTransfer.isSuccess) {
    navigate(`/${role}/stock-operation/pending-operation`);
  }

  useEffect(() => {
    if (resultTransfer.isSuccess) {
      toast({
        title: "Success",
        description: "Your Transfer has been Created. Waiting for approval.",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });
    }
  }, [resultTransfer, toast]);
  return (
    <Stack>
      <form onSubmit={transferHandleSubmit}>
        <Stack
          direction={{ base: "column", xl: "row" }}
          justify="space-between"
        >
          <PageCardComponent>
            <FormControl mb="3">
              <FormLabel>Origin Location</FormLabel>
              <Input
                _disabled={{ color: "blackAlpha.900", cursor: "not-allowed" }}
                defaultValue={originLocation ? originLocation.label : ""}
                isDisabled={true}
              />
            </FormControl>
          </PageCardComponent>

          <PageCardComponent>
            <Controller
              control={control}
              name="destinationLocation"
              rules={{ required: "Select destination location" }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error }
              }) => (
                <FormControl
                  mb="3"
                  isInvalid={!!error}
                  id="destinationLocation"
                >
                  <FormLabel>Destination Location</FormLabel>
                  <Select<
                    DestinationLocationGroup,
                    true,
                    GroupBase<DestinationLocationGroup>
                  >
                    name={name}
                    ref={ref}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    options={destinationLocationOptions}
                    placeholder="Select destination"
                    closeMenuOnSelect={true}
                  />
                  <FormErrorMessage>
                    {errors.destinationLocation?.message}
                  </FormErrorMessage>
                </FormControl>
              )}
            />
          </PageCardComponent>
        </Stack>
        {fields.map((field, index) => {
          return (
            <Stack
              direction={{ base: "column", xl: "row" }}
              width="100%"
              bgColor={"white"}
              borderRadius="md"
              spacing={4}
              px="4"
              py="2"
              key={field.id}
              mt="2"
            >
              <Box w={{ base: "100%", md: "100%", xl: "50%" }}>
                <Controller
                  control={control}
                  name={`stockOperationItems.${index}.product`}
                  rules={{ required: "Select product" }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { error }
                  }) => (
                    <FormControl
                      isInvalid={!!error}
                      id={`stockOperationItems.${index}.product`}
                    >
                      {index === 0 && <FormLabel>Select Product</FormLabel>}
                      <Select<ProductGroup, true, GroupBase<ProductGroup>>
                        name={name}
                        ref={ref}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        options={RemoveSelectedProductFromAllProducts(
                          productOptions,
                          watch
                        )}
                        placeholder="Select product"
                        closeMenuOnSelect={true}
                      />
                      <FormErrorMessage>
                        {error && error.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
              </Box>
              <Box w={{ base: "100%", md: "100%", xl: "10%" }}>
                <FormControl>
                  {index === 0 && <FormLabel>Unit</FormLabel>}
                  <Input
                    _disabled={{
                      color: "blackAlpha.900",
                      cursor: "not-allowed"
                    }}
                    defaultValue={watch[index]?.product?.unit}
                    disabled={true}
                    placeholder="Product unit"
                  />
                </FormControl>
              </Box>
              <Box w={{ base: "100%", md: "100%", xl: "10%" }}>
                <FormControl>
                  {index === 0 && <FormLabel>Available</FormLabel>}
                  <Input
                    _disabled={{
                      color: "blackAlpha.900",
                      cursor: "not-allowed"
                    }}
                    defaultValue={watch[index]?.product?.quantity}
                    disabled={true}
                    placeholder="Available"
                  />
                </FormControl>
              </Box>
              <Box w={{ base: "100%", md: "100%", xl: "15%" }}>
                <FormControl
                  isDisabled={watch[index]?.product?.type === ProductTrackingTypeOptions.NONE ? true : false}
                  isInvalid={
                    errors?.stockOperationItems?.[index]?.trackingNumber ? true : false
                  }
                >
                  {index === 0 && <FormLabel>Tracking Number</FormLabel>}
                  <Input
                    {...register(
                      `stockOperationItems.${index}.trackingNumber` as const,
                      {
                        validate: {
                          required: value => {

                            if (watch[index]?.product?.type === ProductTrackingTypeOptions.NONE || value !== "") return true
                            return "Give Tracking data";
                          },
                        }
                      }

                    )}

                    key={`tracking${index}`}
                    type={"string"}
                    placeholder="Tracking Number"
                  />
                  <FormErrorMessage>
                    {
                      errors?.stockOperationItems?.[index]?.trackingNumber?.message
                    }
                  </FormErrorMessage>
                </FormControl>
              </Box>
              <Box w={{ base: "100%", md: "100%", xl: "15%" }}>
                <FormControl
                  mb="3"
                  isInvalid={
                    errors?.stockOperationItems?.[index]?.quantity
                      ? true
                      : false
                  }

                >
                  {index === 0 && <FormLabel>Quantity</FormLabel>}
                  <Input
                    {...register(
                      `stockOperationItems.${index}.quantity` as const,
                      {
                        validate: {
                          required: value => {
                            if (watch[index]?.product?.type === ProductTrackingTypeOptions.SERIALIZED || value > 0) return true
                            return "Give Amount";
                          },
                        }
                      }
                    )}
                    key={`quantity${index}`}
                    disabled={watch[index]?.product?.type === "SERIALIZED" ? true : false}
                    defaultValue={watch[index]?.product?.type === "SERIALIZED" ? 1 : ""}
                    placeholder="Enter product quantity"
                  />
                  <FormErrorMessage>
                    {errors?.stockOperationItems?.[index]?.quantity?.message}
                  </FormErrorMessage>
                </FormControl>
              </Box>
              <ButtonGroup>
                <Button
                  mt={index === 0 ? 8 : 0}
                  colorScheme="red"
                  variant="outline"
                  leftIcon={<FaTimes />}
                  onClick={() => remove(index)}
                  isDisabled={index > 0 ? false : true}
                >
                  Remove
                </Button>
              </ButtonGroup>
            </Stack>
          );
        })}
        <Stack spacing={4}>
          <Button
            my="2"
            width="100%"
            colorScheme="blue"
            variant="outline"
            leftIcon={<FaPlus />}
            onClick={() => append({})}
          >
            Add
          </Button>

          <Textarea
            placeholder="Enter notes for this Operation"
            {...register("notes")}
          />

          <Button
            width="100%"
            colorScheme="blue"
            onClick={transferConfirmAlert.onOpen}
          >
            Transfer
          </Button>
          <ConfirmAlertComponent
            cancelRef={cancelRef}
            onClose={transferConfirmAlert.onClose}
            isOpen={transferConfirmAlert.isOpen}
            onOpen={transferConfirmAlert.onOpen}
            handleApprove={transferHandleSubmit}
            status={resultTransfer}
          />
        </Stack>
      </form>
    </Stack>
  );
};
export default TransferComponent;
