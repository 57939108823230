import React from "react";
import { Stack } from "@chakra-ui/react";
import { OperationItemEditComponent } from "../components/operation-item-edit.component";

interface OperationItemEditPageProps {}

export const OperationItemEditPage: React.FC<OperationItemEditPageProps> = (
  props: OperationItemEditPageProps
) => {
  return (
    <Stack>
      <OperationItemEditComponent />
    </Stack>
  );
};
