import { StockOperationType } from "../modules/stock-operation/types/stock-operation.type";

export interface RegisterRequest {
  data: {
    user: {
      name: string;
      email: string;
      contact: string;
      password: string;
    };
  };
}

export interface RegisterResponse {
  message: string;
}

export interface LoginRequest {
  data: {
    user: {
      email: string;
      password: string;
    };
  };
}
export interface LoginResponse {
  data: {
    accessToken: string;
    role: {
      roleName: string;
    };
  };
}

export interface ForgetPasswordRequest {
  data: {
    email: string;
  };
}
export interface ForgetPasswordResponse {}

export interface AddProductRequest {
  data: {
    product: {
      name: string;
      isEdcl: boolean;
      unit: string;
      trackingType: string;
      ownerDepartment: string;
      notes?: string;
      medicineGenericId?: string;
      lowStockAmount: number;
    };
  };
}

export interface AddProductResponse {
  message: string;
}

export interface GetProductRequest {}

export interface GetProductResponse {
  data: {
    products: {
      id: number;
      name: string;
      trackingType: string;
      unit: string;
    }[];
  };
}

export interface GetProductLocationInventoryRequest {
  data: {
    product: {
      id: number;
    };
  };
}

export interface GetProductLocationInventoryResponse {
  data: {
    location: {
      id: number;
      name: string;
    };
    quantity: string;
  }[];
}

export interface GetFilterProductRequest {
  id: number;
}

export interface GetFilterProductResponse {
  data: {
    products: {
      id: number;
      name: string;
      type: string;
      unit: string;
      quantity: number;
      genericName?: string;
    }[];
  };
}

export interface GetFilterItemResponse {
  data: {
    items: {
      id: number;
      productId: number;
      name: string;
      unit: string;
      quantity: number;
      type: string;
      trackingData: string | null;
      expirydate: string | null;
      manuFacturngDate: string | null;
    }[];
  };
}

export interface GetAllProductsRequest {
  query: {
    search: string;
    pageIndex: number;
    pageSize: number;
  };
}

export interface Product {
  id: number;
  name: string;
  isDisabled: boolean;
  isEdcl: boolean;
  ownerDepartment: string;
  unit: string;
  trackingType: string;
  notes?: string;
  medicineGenericId?: string;
  medicineGeneric?: MedicineGeneric;
  lowStockAmount: number;
}

export interface MedicineGeneric {
  id: string;
  genericName: string;
  drugClass?: string;
}

export interface GetAllProductsResponse {
  data: {
    products: Product[];
    productCount: number;
  };
}

export interface GetSearchedProductsRequest {
  query: {
    text: string;
    pageIndex: number;
    pageSize: number;
  };
}

export interface GetSearchedProductsResponse {
  data: {
    products: Product[];
    productCount: number;
  };
}

export interface LastAvailableTransferRequest {
  data: {
    product: {
      productId: number;
      originLocationId: number;
      destinationLocationId: number;
    };
  };
}
export interface LastAvailableTransferResponse {
  data: {
    availableQuantity: number;
    lastTransferQuantity: number;
    lastTransferDate: string;
  };
}

export interface AddLocationRequest {
  data: {
    location: {
      name: string;
      type: string;
      department: string;
      parentID?: string;
    };
  };
}

export interface AddLocationResponse {
  message: string;
}

export interface SelectLocationRequest {
  data: {
    location: {
      parentLocation: number;
    };
  };
}

export interface SelectLocationResponse {
  message: string;
}

export interface GetLocationRequest {}

export interface GetLocationResponse {
  data: {
    locations: {
      type: string;
      id: number;
      name: string;
      isDisabled: boolean;
    }[];
  };
}

export interface GetSubLocationRequest {
  data: {
    location: {
      id: number;
    };
  };
}

export interface GetSubLocationResponse {
  data: {
    location: {
      type: string;
      id: number;
      name: string;
      isDisabled: boolean;
      parentLocationId: number;
    }[];
  };
}

// get all locations
export interface GetAllLocationsRequest {
  query: {
    search: string;
    pageIndex: number;
    pageSize: number;
  };
}

export interface GetAllLocationsResponse {
  data: {
    locations: Location[];
    locationCount: number;
  };
}

export interface GetSearchedLocationsRequest {
  query: {
    text: string;
    pageIndex: number;
    pageSize: number;
  };
}

export interface GetSearchedLocationsResponse {
  data: {
    locations: Location[];
    locationCount: number;
  };
}

export interface TransferRequest {
  data: {
    stockOperation: {
      originLocationId: number;
      destinationLocationId: number;
      demandStockOperationId?: number;
      stockOperationItems: {
        productId: number;
        quantity: number;
      }[];
      stockOperationApprovers?: {
        approverId: number;
      }[];
      notes?: string;
      invoiceNo?: string;
    };
  };
}
export interface EditTransferRequest {
  data: {
    stockOperation: {
      id: number;
      operationStatus: string;
      stockOperationItems: {
        id?: number;
        quantity: number;
        productId: number;
        trackingNumber?: string;
      }[];
      deletedStockOperationItems: { id: number }[];
      stockOperationApprovers?: {
        id?: number;
        approverId: number;
      }[];
      deletedStockOperationApprovers?: { id: number };
    };
  };
}
export interface TransferResponse {
  data: {
    createdAt: string;
    createdById: string;
    destinationLocationId: number;
    id: number;
    notes: string;
    operationStatus: string;
    operationType: StockOperationType;
    originLocationId: number;
    updatedAt: string;
  };
}

export interface InitialInventoryRequest {
  data: {
    stockOperation: {
      locationId: number;
      stockOperationItems: {
        productId: number;
        quantity: number;
        trackingNumber?: string;
        manufactureDate?: string;
        expairyDate?: string;
      }[];
      notes: string;
      stockOperationApprovers?: {
        approverId: number;
      }[];
    };
  };
}

export interface InitialInventoryResponse {
  message: string;
}

export interface DemandInventoryRequest {
  data: {
    stockOperation: {
      originLocationId: number;
      destinationLocationId: number;
      stockOperationItems: {
        productId: number;
        quantity: number;
      }[];
      stockOperationApprovers?: {
        approverId: number;
      }[];
      notes?: string;
    };
  };
}

export interface DemandOperationResponse {
  data: {
    createdAt: string;
    createdById: string;
    destinationLocationId: number;
    id: number;
    notes: string;
    operationStatus: string;
    operationType: StockOperationType;
    originLocationId: number;
    updatedAt: string;
  };
}

/**
 * REPORTS API TYPES
 */

// Location Inventory Types

export interface GetLocationInventoryRequest {
  data: {
    report: {
      locationId: number;
      filterBy?: string;
    };
  };
}
export interface GetPreviousInventoryRequest {
  data: {
    report: {
      locationId: number;
      date: string;
    };
  };
}
export interface TrackingData {
  amount: number;
  trackingId: string;
  expiryDate: string;
  manufactureDate: string;
}
export interface LocationInventory {
  productId: number;
  productName: string;
  quantity: number;
  unit: string;
  ownerDepartment: string;
  sku: string;
  trackingType: string;
  trackingData: TrackingData[];
}
export interface SingleProductInventory {
  productId: number;
  productName: string;
  unit: string;
  trackingType: string;
  locationInventory: {
    locationId: number;
    locationName: string;
    locationType: string;
    quantity: number;
    sku: string;

    trackingData: TrackingData[];
  }[];
}

export interface SingleProductAllLocationInventory {
  locationId: number;
  locationName: string;
  locationType: string;

  quantity: number;
  sku: string;

  trackingData: TrackingData[];
}

export interface GetPreviousInventoryResponse {
  data: {
    previousInventory: LocationInventory[];
    reportDate: string;
  };
}

// All Operations Types
export interface GetLocationInventoryResponse {
  data: {
    inventory: LocationInventory[];
    location: Location;
  };
}
export interface GetPendingOperationRequest {}
export interface GetPendingOperationResponse {
  data: {
    stockOperations: Operations[];
    operationsCount: number;
  };
}
export interface ApprovePendingOperationRequest {
  data: {
    stockOperation: {
      id: number;
    };
  };
}
export interface ApprovePendingOperationResponse {}

export interface CancelPendingOperationRequest {
  data: {
    stockOperation: {
      id: number;
    };
  };
}
export interface CancelPendingOperationResponse {}

export interface GetAllOperationRequest {
  query: {
    pageIndex: number;
    pageSize: number;
    startTime: string;
    endTime: string;
  };
}
export interface GetAllUsersRequest {
  query: {
    search: string;
    pageIndex: number;
    pageSize: number;
  };
}

export interface User {
  id: number;
  name: string;
  email: string;
  contact: string;
  locations: {
    name: string;
    department: string;
  }[];
  roles: {
    roleName: string;
  }[];
}

export interface GetAllUsersResponse {
  data: {
    user: User[];
    userCount: number;
  };
}

export interface GetSearchedUsersRequest {
  query: {
    text: string;
    pageIndex: number;
    pageSize: number;
  };
}

export interface GetSearchedUsersResponse {
  data: {
    user: User[];
    userCount: number;
  };
}

export interface OperationProduct {
  id: number;
  name: string;
  unit: string;
  ownerDepartment: string;
  isDisabled: boolean;
  trackingType: string;
  sku: string;
}
export interface StockOperationApprover {}
export interface StockOperationItem {
  id: number;
  product: OperationProduct;
  productId: number;
  quantity: number;
  stockOperationId: number;
  trackingNumber?: string;
  itemNote?: string;
}
export interface DestinationLocation {
  id: number;
  department: string;
  locationType: string;
  name: string;
}
export interface OriginLocation {
  id: number;
  department: string;
  locationType: string;
  name: string;
}
export interface CreatedBy {
  id: string;
  name: string;
  email: string;
  contact: string;
}
export interface Operations {
  id: number;
  stockOperationApprovers: StockOperationApprover[];
  stockOperationItems: StockOperationItem[];
  operationStatus: string;
  operationType: string;
  destinationLocation: DestinationLocation;
  originLocation: OriginLocation;
  createdBy: CreatedBy;
  createdAt: string;
  notes?: string;
  invoiceNo?: string;
  prescription?: {
    id: string;
    type: string;
    patientId: string;
    patient?: {
      contactNumber: string;
      id: string;
      name: string;
    };
  };
}
export interface DemandFulfillOperations {
  id: number;
  stockOperationApprovers: StockOperationApprover[];
  stockOperationItems: DemandOperationItem[];
  operationStatus: string;
  operationType: string;
  destinationLocation: DestinationLocation;
  originLocation: OriginLocation;
  createdBy: CreatedBy;
  createdAt: string;

  notes?: string;
}
export interface DemandOperationItem {
  id: number;
  product: OperationProduct;
  productId: number;
  quantity: number;
  stockOperationId: number;
  trackingNumber?: string;
  availableQuantity: number;
}
export interface GetAllOperationResponse {
  data: {
    operations: Operations[];
    operationsCount: number;
    startTime: string;
    endTime: string;
  };
}

export enum segmentationType {
  All = "ALL",
  INBOUND = "INBOUND",
  OUTBOUND = "OUTBOUND"
}

// Location Operations
export interface LocationOperationsRequest {
  data: {
    report: {
      locationId: number;
      startDate: string;
      endDate: string;
      filter: string;
    };
  };
}
export interface LocationOperationsResponse {
  data: {
    operations: Operations[];
  };
}

// All Operation Details Types
export interface GetAllOperationDetailsRequest {
  data: {
    report: {
      operationId: number;
    };
  };
}

export interface GetAllOperationDetailsResponse {
  data: {
    operationDetails: Operations;
  };
}

export interface UpdateNoteRequest {
  data: {
    notes: {
      id: number;
      notes: string;
    };
  };
}

export interface UpdateNoteResponse {
  message: string;
}
export interface GetDemandFulFillDetailsResponse {
  data: {
    operationDetails: DemandFulfillOperations;
  };
}
export interface GetDemandPrintDataRequest {
  data: {
    report: {
      demandStockOperationId: number;
    };
  };
}

export interface GetDemandPrintDataResponse {
  createdAt: string | null;
  createdBy: { name: string };
  createdById: string;
  destinationLocation: {
    id: number;
    name: string;
    locationType: string;
    department: string;
  };
  destinationLocationId: number;
  id: number;
  notes: string;
  operationStatus: string;
  operationType: string;
  originLocation: {
    id: number;
    name: string;
    locationType: string;
    department: string;
  };
  originLocationId: number;
  stockOperationApprovers: any[];
  stockOperationItems: demandPrintSingleItem[];
  updatedAt: string;
}

export interface GetVendorDemandDataRequest {
  data: {
    report: {
      demandId: number;
    };
  };
}

export interface GetVendorDemandDataResponse {
  createdAt: string | null;
  createdBy: { name: string; contact: string };
  createdById: string;
  destinationLocation: {
    id: number;
    name: string;
    locationType: string;
    department: string;
  };
  destinationLocationId: number;
  id: number;
  notes: string;
  operationStatus: string;
  operationType: string;
  originLocation: {
    id: number;
    name: string;
    locationType: string;
    department: string;
  };
  originLocationId: number;
  stockOperationApprovers: any[];
  monthDuration: string;
  stockOperationItems: vendorDemandSingleItem[];
  updatedAt: string;
}
export interface vendorDemandSingleItem {
  productId: number;
  tenderSerialNumber: number;
  productName: string;
  lastSupplyAmount: number;
  lastSupplyDate: any;
  currentInventory: number;
  monthlyRequirment: number;
  currentRequirment: number;
  appTotalAmount: number;
  appPurchasedAmount: number;
}

export interface demandPrintSingleItem {
  available: number;
  demandAmount: number;
  lastDemand: number;
  lastTransfer: number;
  lastTransferDate: string;
  productId: number;
  productName: string;
  transferAmount: number;
}

// Daily Product Report
export interface DailyProductReportRequest {
  data: {
    report: {
      locationId: number;
      startDate: string;
      endDate: string;
    };
  };
}

export interface OperationHistory {
  operationStatus: string;
  operationType: string;
  destinationLocation: DestinationLocation;
  originLocation: OriginLocation;
  createdBy: CreatedBy;
  createdAt: string;
  quantity: number;
  notes: string;
}

export interface ProductOperation {
  productId: number;
  name: string;
  isEdcl: boolean;
  unit: string;
  inwardCount: number;
  outboundCount: number;
  operations: OperationHistory[];
}

export interface DailyProductReportResponse {
  data: {
    productOperationSummary: ProductOperation[];
    startDate: string;
    endDate: string;
    reportType: string;
    location: Location;
  };
}
export interface GetAuthorizedLocationsRequest {}
export interface GetAuthorizedLocationsResponse {
  data: {
    locations: {
      id: number;
      name: string;
    }[];
  };
}

export interface GetAssignUserLocationRequest {}

export interface GetProductAllLocationInventoryRequest {
  id: string | undefined;
}
export interface GetProductAllLocationInventoryResponse {
  productId: number;
  productName: string;
  unit: string;
  notes: string;
  trackingType: string;
  overAllTotal: number;
  locationInventory: {
    locationId: number;
    locationName: string;
    locationType: string;
    quantity: number;
    trackingData: TrackingData[];
  }[];
}
export interface Location {
  id: number;
  name: string;
  department: string;
  locationType: string;
  parentLocationId?: number;
  isDisabled: boolean;
}

export interface GetAssignUserLocationResponse {
  data: {
    locations: Location[];
    locationCount: number;
  };
}

export interface GetAssignUserRequest {}

export interface User {
  id: number;
  name: string;
  email: string;
  contact: string;
}

export interface GetAssignUserResponse {
  data: {
    user: User[];
    userCount: number;
  };
}

export interface AssignUserPermissionRequest {
  data: {
    locationId: number;
    userId: number;
  };
}

export interface AssignUserPermissionResponse {
  message: string;
}

export interface RemoveUserLocationPermissionRequest {
  data: {
    locationId: number;
    userId: number;
  };
}

export interface RemoveUserLocationPermissionResponse {
  message: string;
}

export interface AssignedUserRolePermissionRequest {
  data: {
    assignTo: number;
    roleName: string;
  };
}

export interface AssignedUserRolePermissionResponse {
  message: string;
}

export interface GetProductDetailsRequest {
  data: {
    product: {
      id: number;
    };
  };
}

export interface GetProductDetailsResponse {
  data: {
    product: Product;
  };
}

export interface GetProductQuantityRequest {
  data: {
    product: {
      productId: number;
      locationId: number;
      productType: string;
    };
  };
}

export interface GetProductQuantityResponse {
  data: {
    products: {
      productId: number;
      quantity: number;
    };
  };
}

export interface EditProductRequest {
  data: {
    product: {
      id: number;
      name: string;
      isEdcl: boolean;
      unit: string;
      ownerDepartment: string;
      trackingType: string;
      isDisabled: boolean | null;
      sku?: string;
      medicineGenericId?: string;
      lowStockAmount: number;
    };
  };
}

export interface EditProductResponse {
  message: string;
}

export interface GetLocationDetailsRequest {
  data: {
    location: {
      locationId: number;
    };
  };
}

export interface GetLocationDetailsResponse {
  location: Location;
}

export interface EditLocationRequest {
  data: {
    location: {
      locationId: number;
      name: string;
      locationType: string;
      department: string;
      parentLocation?: number | string;
    };
  };
}

export interface EditLocationResponse {
  message: string;
}

export interface Roles {
  roleName: string;
}

export interface UserProfileRequest {}

export interface UserProfileResponse {
  data: {
    user: {
      name: string;
      locations: Location[];
      roles: Roles[];
    };
  };
}

export interface DashboardCountRequest {
  query: {
    locationId: number;
    role: string;
  };
}

export interface DashboardCountResponse {
  data: {
    operationCount: number;
    productCount: number;
    locationCount: number;
  };
}

export interface VendorSupplyRequest {
  data: {
    stockOperation: {
      locationId: number;
      stockOperationItems: {
        productId: number;
        quantity: number;
        trackingNumber?: string;
        manufactureDate?: string;
        expiryDate?: string;
      }[];
      notes: string;
    };
  };
}
export interface VendorSupplyResponse {
  data: {
    createdAt: string;
    createdById: string;
    destinationLocationId: number;
    id: number;
    notes: string;
    operationStatus: string;
    operationType: StockOperationType;
    originLocationId: number;
    updatedAt: string;
  };
}

export interface ChangePasswordRequest {
  data: {
    user: {
      oldPassword: string;
      newPassword: string;
    };
  };
}

export interface ChangePasswordResponse {
  message: string;
}

export interface ResetPasswordRequest {
  data: {
    password: string;
    confirmPassword: string;
    token: string;
    userId: string;
  };
}

export interface ResetPasswordResponse {
  message: string;
}

export interface GetMeRequest {}
export interface GetMeResponse {
  data: {
    user: {
      name: string;
      roles: {
        roleName: string;
      }[];
      id: string;
      locations: {
        name: string;
        id: number;
      }[];
    };
  };
}

export interface CustomReportRequest {
  data: {
    report: {
      reportType: string;
      startDate: string;
      endDate: string;
      groupBy: string;
      location: number[];
      product: number[];
    };
  };
}

export interface CustomReportResponse {}

export interface AdjustmentRequest {
  data: {
    stockOperation: {
      locationId: number;
      productId: number;
      quantity: number;
      trackingNumber: string;
      notes: string;
    };
  };
}
export interface AdjustmentResponse {}

export interface DistributionRequest {
  data: {
    stockOperation: {
      locationId: number;
      stockOperationItems: {
        productId: number;
        quantity: number;
        trackingNumber?: string;
        itemNote?: string;
      }[];
      notes: string;
      stockOperationApprovers?: {
        approverId: number;
      }[];
      distributedTo?: {
        prescription: {
          id: string;
          type: string;
        };
        patient: {
          id: string;
          name: string;
          contactNumber: string;
        };
      };
    };
  };
}

export interface DistributionResponse {
  message: string;
}

export interface VendorDemandRequest {
  data: {
    stockOperation: {
      originLocationId: number;
      monthDuration: string;
      notes: string;
      stockOperationItems: {
        productId: number;
        tenderSerialNumber: number;
        appTotalAmount: number;
        appPurchasedAmount: number;
        monthlyRequirment: number;
        currentRequirment: number;
      }[];
    };
  };
}
export interface VendorDemandResponse {}

export interface VendorOperation {
  id: number;
  operationStatus: string;
  operationType: string;
  originLocation: OriginLocation;
  createdBy: CreatedBy;
  createdAt: string;
}

export interface AllVendorDemandRequest {
  data: {
    report: {
      locationId: number;
    };
  };
}
export interface AllVendorDemandResponse {
  data: {
    operations: VendorOperation[];
  };
}

export enum ProductTrackingTypeOptions {
  SERIALIZED = "SERIALIZED",
  BATCH = "BATCH",
  NONE = "NONE"
}

export enum AllOrganizationName {
  DMCH = "DMCH",
  CMCH = "CMCH",
  SHMCH = "SHMCH",
  BINDULOGIC = "BINDULOGIC"
}

export interface MonthlyAdjustmentRequest {
  data: {
    report: {
      locationId: number;
      month: string;
    };
  };
}

export interface DistributedPrescription {
  distributedProducts: DistributedProduct[];
  prescriptionId: string;
  count: number;
}

export interface DistributedProduct {
  productId: number;
  name: string;
  genericName?: string | undefined;
  unit: string;
  quantity: number;
}

export interface MonthlyAdjustmentRespone {
  data: {
    productOperationSummary: ProductOperation[];
    month: string;
    reportType: string;
    location: Location;
  };
}

export interface GetOperationPrintDataRequest {
  data: {
    report: {
      operationId: number;
    };
  };
}

export interface GetOperationPrintDataResponse {
  data: {
    operationDetails: {
      createdAt: string;
      createdBy: { name: string; contact: number };
      createdById: string;
      destinationLocation: {
        id: number;
        name: string;
        locationType: string;
        department: string;
      } | null;
      destinationLocationId: number | null;
      id: number;
      invoiceNo?: string;
      notes?: string;
      operationStatus: string;
      operationType: string;
      originLocation: {
        id: number;
        name: string;
        locationType: string;
        department: string;
      };
      originLocationId: number;
      stockOperationApprovers: any[];
      stockOperationItems: singleOperationPrintItem[];
      updatedAt: string;
      prescription?: {
        id: string;
        type: string;
        patientId: string;
        patient?: {
          contactNumber: string;
          id: string;
          name: string;
        };
      };
    };
  };
}

export interface singleOperationPrintItem {
  id: number;
  product: {
    id: number;
    name: string;
    notes: number | string;
    ownerDepartment: string;
    sku: string;
    trackingType: string;
    unit: string;
  };
  productId: number;
  quantity: number;
  stockOperationId: number;
  trackingNumber: number | string;
}

export interface GetLocationInventoryPrintRequest {
  data: {
    report: {
      locationId: number;
    };
  };
}

export interface GetLocationInventoryPrintResponse {
  data: {
    inventory: LocationInventory[];
    location: {
      id: number;
      department: string;
      isDisabled: boolean;
      locationType: string;
      name: string;
    };
  };
}

export interface UpdateExpiryDateAndManufactureDateRequest {
  data: {
    productId: number;
    trackingNumber: string;
    expiryDate?: string;
    manufactureDate?: string;
  };
}

export interface UpdateExpiryDateAndManufactureDateResponse {}
export interface UpdateProductTrackingNumberRequest {
  data: {
    productId: number;
    oldTrackingNumber: string;
    newTrackingNumber: string;
    trackingType: string;
  };
}

export interface UpdateProductTrackingNumberResponse {}

export enum allUIType {
  DMCH = "DMCH",
  CMCH = "CMCH"
}

export interface SingleProductMovementRequest {
  data: {
    locationId: number;
    productId: number;
    startDate: string;
    endDate: string;
  };
}

export interface LocationMovementSummary {
  LocationId: number;
  count: number;
  history: LocationMovementHistory[];
  inComing: number;
  outGoing: number;
  name: string;
}

export interface DateWiseMovementSummary {
  date: string;
  count: number;
  history: LocationMovementHistory[];
  inComing: number;
  outGoing: number;
}

export interface SingleProductMovementResponse {
  data: {
    endTime: string;
    startTime: string;
    totalIn: number;
    totalOut: number;
    totalCount: number;
    product: {
      id: number;
      name: string;
      sku: string;
      ownerDepartment: string;
      trackingType: string;
      unit: string;
    };
    originLocation: {
      department: string;
      id: number;
      isDisabled: boolean;
      locationType: string;
      name: string;
      parentLocationId: number | null;
    };
    locationMovementSummary: LocationMovementSummary[];
  };
}

export interface DateWiseProductMovementRequest {
  data: {
    locationId: number;
    productId: number;
    startDate: string;
    endDate: string;
  };
}

export interface DateWiseProductMovementResponse {
  endTime: string;
  startTime: string;
  totalIn: number;
  totalOut: number;
  totalCount: number;
  product: {
    id: number;
    name: string;
    sku: string;
    ownerDepartment: string;
    trackingType: string;
    unit: string;
  };
  originLocation: {
    department: string;
    id: number;
    isDisabled: boolean;
    locationType: string;
    name: string;
    parentLocationId: number | null;
  };
  dateWiseMovementSummary: DateWiseMovementSummary[];
}

export interface LocationMovementHistory {
  stockOperationId: number;
  quantity: number;
  originLocation: {
    id: number;
    name: string;
  };
  operationType: string;
  operationStatus: string;
  isIncrement: boolean;
  destinationLocation: {
    id: number;
    name: string;
  } | null;
  createdBy: {
    name: string;
    id: string;
  };
  createdAt: string;
  trackingNumber?: string;
  invoiceNo?: string;
  notes?: string | null;
}

export interface GetAnnualProductReportRequest {
  data: {
    locationId: number;
    startDate: string;
    endDate: string;
  };
}

export interface GetAnnualProductReportResponse {
  productPrediction: ProductPrediction[];
  startDate: string;
  endDate: string;
  reportViewTime: string;
  location: Location;
}

export interface ProductPrediction {
  productId: number;
  name: string;
  unit: string;
  totalIn: number;
  totalOut: number;
}

export interface SearchGenericsRequest {
  query: {
    keyword: string;
  };
}
export interface SearchGenericsResponse {
  genericNames: {
    id: string;
    genericName: string;
    drugClass: string;
  }[];
}

export interface GetPrescriptionRequest {
  query: {
    prescriptionId: string;
  };
}
export interface GetPrescriptionResponse {
  prescription: {
    id: string;
    type: "OPD Prescription" | "IPD Prescription";
  };
  patient: {
    id: string;
    name: string;
    contactNumber: string;
  };
  medicineGenerics: {
    name: string;
    quantity: number;
  }[];
  distributedProducts?: {
    productId: number;
    name: string;
    genericName?: string;
    unit: string;
    quantity: number;
  }[];
}

export interface GetLowStockRequest {
  query: {
    locationId: number;
    role: string;
  };
}
export interface GetLowStockResponse {
  lowStock: LocationInventory[];
  lowStockCount: number;
  location: {
    id: number;
    department: string;
    isDisabled: boolean;
    locationType: string;
    name: string;
  };
}

export interface GetToBeExpiredItemsRequest {
  query: {
    locationId: number;
    role: string;
  };
}
export interface GetToBeExpiredItemsResponse {
  allItems: {
    productId: number;
    productName: string;
    unit: string;
    type: string;
    trackingId: string;
    quantity: number;
    locationName: string;
    expDate: Date | null;
  }[];
  location: {
    id: number;
    department: string;
    isDisabled: boolean;
    locationType: string;
    name: string;
  };
  type: string;
  totalItems: number;
}

export interface UpdatePasswordRequest {
  data: {
    adminPassword: string;
    userEmail: string;
    userPassword: string;
    userConfirmPassword: string;
  };
}

export interface UpdatePasswordResponse {
  message: string;
}

export interface GetNotificationsRequest {
  query: {
    pageIndex?: string;
    pageSize?: string;
    isRead?: string;
  };
}

export enum NotificationEntityType {
  STOCK_OPERATION,
  PENDING_STOCK_OPERATION,
  PRODUCT,
  LOCATION,
  USER
}
export interface GetNotificationsResponse {
  listOfNotification: {
    id: string;
    title: string;
    description: string;
    entityType: NotificationEntityType;
    entityId: string;
    createdAt: string;
    notificationStatuses: {
      notificationId: string;
      userId: string;
      isRead: boolean;
      readAt: string | null;
    }[];
  }[];
  totalCount: { total: number; read: number; unRead: number };
}

export interface ReadNotificationRequest {
  notificationId: string;
}

export interface ReadNotificationResponse {}
export interface GetDistributionForPrescriptionRequest {
  locationId: number;
  startDate: string;
  endDate: string;
}
export interface GetDistributionForPrescriptionResponse {
  startDate: string;
  endDate: string;
  originLocation: {
    department: string;
    id: number;
    isDisabled: boolean;
    locationType: string;
    name: string;
    parentLocationId: number | null;
  };
  distributedPrescriptions: DistributedPrescription[];
  overAllDistributedProducts: DistributedProduct[];
  totalOperationCount: number;
}

export interface OperationItemEditRequest {
  data: {
    operationId: number;
    itemId: number;
    updatedQuantity: number;
  };
}

export interface OperationItemEditResponse {
  message: string;
}
