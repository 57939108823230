import { useEffect, useRef, useState } from "react";
import {
  Stack,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  ButtonGroup,
  useToast,
  Textarea,
  useDisclosure,
  Box,
  Switch
} from "@chakra-ui/react";
import {
  useTransferOperationMutation,
  useGetAssignUserLocationsQuery,
  useGetFilterProductQuery,
  useGetProductQuantityQuery
} from "../../../../api";
import { Select, GroupBase } from "chakra-react-select";
import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";
import {
  DestinationLocationGroup,
  ENTER_KEY,
  fieldNameType,
  itemGroup,
  OriginLocationGroup,
  ProductGroup,
  StockOperationItems
} from "../../types/stock-operation.type";
import { FaPlus, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { PageCardComponent } from "../../../core/components/page-card.component";
import {
  RemoveSelectedProductFromAllProducts,
  userCurrentRole
} from "../../../../utils/common-functions";
import { ConfirmAlertComponent } from "../confirm-alert.component";
import { ProductTrackingTypeOptions } from "../../../../api/type";
import { OperationService } from "../../../../service/operation/operation.service";
import { AddItemComponent } from "../add-item.component";

type TransferFormValues = {
  originLocation: OriginLocationGroup;
  destinationLocation: DestinationLocationGroup;
  stockOperationItems: StockOperationItems[];
  notes: string;
  invoiceNo: string;
};

const CmchTransferComponent = () => {
  const [isNewAddOn, setIsNewAddOn] = useState<boolean>(false);
  const id = localStorage.getItem("location");
  const toast = useToast();
  const navigate = useNavigate();
  const role = userCurrentRole();
  const transferConfirmAlert = useDisclosure();
  const cancelRef = useRef();
  const getLocationQuery = useGetAssignUserLocationsQuery({});

  const [originLocationOptions, setOriginLocationOptions] = useState<
    OriginLocationGroup[]
  >([]);
  const [originLocation, setOriginLocation] = useState<OriginLocationGroup>();

  const [destinationLocationOptions, setDestinationLocationOptions] = useState<
    DestinationLocationGroup[]
  >([]);

  useEffect(() => {
    if (getLocationQuery.data) {
      const locationsWithValues = getLocationQuery.data?.data.locations.map(
        (location) => {
          return {
            label: location.name,
            value: location.id
          };
        }
      );
      setOriginLocationOptions(locationsWithValues);
      setDestinationLocationOptions(locationsWithValues);
    }
  }, [getLocationQuery.data]);

  useEffect(() => {
    if (originLocationOptions.length) {
      setOriginLocation(
        originLocationOptions.find((d) => d.value === Number(id))
      );
    }
  }, [id, originLocationOptions]);

  const getProductQuery = useGetFilterProductQuery({ id: Number(id) });
  const [createTransfer, createTransferResult] = useTransferOperationMutation();
  const [productOptions, setProductOptions] = useState<ProductGroup[]>([]);

  useEffect(() => {
    if (getProductQuery.data) {
      const nonZeroProducts = getProductQuery.data.data.products;
      const productWithValues = nonZeroProducts
        .filter((item) => item.quantity > 0)
        .map((product) => {
          return {
            label: product.name,
            value: product.id,
            unit: product.unit,
            type: product.type,
            quantity: product.quantity
          };
        });
      setProductOptions(productWithValues);
    }
  }, [getProductQuery.data]);

  const {
    register,
    handleSubmit,
    control,
    setFocus,
    formState: { errors }
  } = useForm<TransferFormValues>({
    defaultValues: {
      stockOperationItems: [{}]
    }
  });

  const { fields, append, insert, update, prepend, remove } = useFieldArray<
    TransferFormValues,
    "stockOperationItems"
  >({
    control,
    name: "stockOperationItems"
  });

  const watch = useWatch({
    control,
    name: "stockOperationItems"
  });

  const transferHandleSubmit = handleSubmit((data) => {
    const allStockOperationItems = data.stockOperationItems.map(
      (singleItem) => {
        return {
          productId: singleItem.product.value,
          quantity: OperationService.getQuantity(
            singleItem.product.type as string,
            singleItem.quantity
          ),
          trackingNumber: singleItem.trackingNumber
        };
      }
    );
    createTransfer({
      data: {
        stockOperation: {
          originLocationId: Number(id),
          destinationLocationId: data.destinationLocation.value,
          stockOperationItems: allStockOperationItems,
          notes: data.notes,
          invoiceNo: data.invoiceNo
        }
      }
    });
  });

  useEffect(() => {
    if (createTransferResult.isSuccess) {
      toast({
        title: "Success",
        description: "Your transfer has been created. Waiting for approval.",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });

      navigate(
        `/${role}/approval/operation-details?id=${createTransferResult.data.data.id}&type=${createTransferResult.data.data.operationType}`
      );
    }
  }, [createTransferResult, toast, navigate, role]);

  const handleAddItem = () => {
    const currentindex = fields.length - 1;
    const nextIndex = currentindex + 1;
    append({});
    setTimeout(() => {
      focusElement(nextIndex, "product");
    }, 10);
  };

  const handleKeyDown = (event: any, index: number, fieldName: string) => {
    if (event.keyCode !== ENTER_KEY) return;
    const lastIndex = fields.length - 1;
    const currentIndex = index;
    const nextIndex = index + 1;

    let currentProductType = watch[index].product.type;

    if (
      currentIndex === lastIndex &&
      currentProductType === ProductTrackingTypeOptions.SERIALIZED &&
      fieldName === "trackingNumber"
    ) {
      append({});
      setTimeout(() => {
        focusElement(nextIndex, "product");
      }, 100);
    } else if (currentIndex === lastIndex && fieldName === "quantity") {
      append({});
      setTimeout(() => {
        focusElement(nextIndex, "product");
      }, 100);
    } else if (
      currentProductType === ProductTrackingTypeOptions.NONE &&
      fieldName === "quantity"
    ) {
      focusElement(nextIndex);
    } else if (
      currentProductType === ProductTrackingTypeOptions.SERIALIZED &&
      fieldName === "trackingNumber"
    ) {
      focusElement(nextIndex);
    } else if (
      currentProductType === ProductTrackingTypeOptions.BATCH &&
      fieldName === "trackingNumber"
    ) {
      focusElement(currentIndex, "quantity");
    } else if (
      currentProductType === ProductTrackingTypeOptions.BATCH &&
      fieldName === "quantity"
    ) {
      focusElement(nextIndex);
    }
  };

  function focusElement(index: number, fieldNameType?: fieldNameType) {
    if (fieldNameType) {
      setFocus(`stockOperationItems.${index}.${fieldNameType}`);
      return;
    }
    let selectedProduct = watch[index].product;
    if (!selectedProduct) {
      setFocus(`stockOperationItems.${index}.product`);
      return;
    }
    let productType = selectedProduct.type;
    if (productType !== ProductTrackingTypeOptions.NONE) {
      setFocus(`stockOperationItems.${index}.trackingNumber`);
      return;
    }
    setFocus(`stockOperationItems.${index}.quantity`);
  }

  const handleProductSelect = (OnChange: any, value: any, index: number) => {
    const currentIndexId = index;
    OnChange(value);
    setTimeout(() => {
      if (value.type) {
        value.type === ProductTrackingTypeOptions.NONE
          ? focusElement(currentIndexId, "quantity")
          : focusElement(currentIndexId, "trackingNumber");
      }
    }, 10);
  };
  const getQuantity = (productType: any) => {
    if (productType === ProductTrackingTypeOptions.SERIALIZED) {
      return 1;
    }
    return "";
  };

  const addSingleItems = (selectedItem: itemGroup, quantity: number) => {
    const lastIndex = fields.length - 1;
    const trackingNumber = selectedItem?.trackingData
      ? selectedItem?.trackingData
      : undefined;
    const newProduct = {
      product: {
        label: selectedItem?.label,
        value: selectedItem?.productId,
        quantity: selectedItem.available,
        unit: selectedItem?.unit,
        type: selectedItem?.type
      },
      quantity: quantity,
      trackingNumber: trackingNumber
    };
    if (fields[lastIndex].product) {
      append(newProduct);
    } else {
      update(lastIndex, newProduct);
    }
  };
  return (
    <Stack>
      <form onSubmit={transferHandleSubmit}>
        <Stack
          direction={{ base: "column", xl: "row" }}
          justify="space-between"
        >
          <PageCardComponent>
            <FormControl mb="3">
              <FormLabel>Origin Location</FormLabel>
              <Input
                _disabled={{ color: "blackAlpha.900", cursor: "not-allowed" }}
                defaultValue={originLocation ? originLocation.label : ""}
                isDisabled={true}
              />
            </FormControl>
          </PageCardComponent>

          <PageCardComponent>
            <Controller
              control={control}
              name="destinationLocation"
              rules={{ required: "Select destination location" }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error }
              }) => (
                <FormControl
                  mb="3"
                  isInvalid={!!error}
                  id="destinationLocation"
                >
                  <FormLabel>Destination Location</FormLabel>
                  <Select<
                    DestinationLocationGroup,
                    true,
                    GroupBase<DestinationLocationGroup>
                  >
                    name={name}
                    ref={ref}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    options={destinationLocationOptions}
                    placeholder="Select destination"
                    closeMenuOnSelect={true}
                  />
                  <FormErrorMessage>
                    {errors.destinationLocation?.message}
                  </FormErrorMessage>
                </FormControl>
              )}
            />
          </PageCardComponent>
        </Stack>

        <Stack spacing={4}>
          <PageCardComponent>
            <FormControl mb="3" alignItems="center">
              <FormLabel textAlign="center">Invoice No</FormLabel>
              <Input
                _disabled={{ color: "blackAlpha.900", cursor: "not-allowed" }}
                {...register("invoiceNo")}
              />
            </FormControl>
          </PageCardComponent>
        </Stack>
        <Stack spacing={4}>
          <Button
            my="2"
            width="100%"
            colorScheme="blue"
            variant="outline"
            leftIcon={<FaPlus />}
            onClick={() => handleAddItem()}
          >
            Add
          </Button>
        </Stack>
        {isNewAddOn && originLocation?.value && (
          <Stack spacing={4}>
            <PageCardComponent>
              <AddItemComponent
                locationId={Number(originLocation?.value)}
                addSingleItems={addSingleItems}
              />
            </PageCardComponent>
          </Stack>
        )}

        {fields
          .slice()
          .reverse()
          .map((field, index) => {
            const currentIndex = fields.length - index - 1;
            return (
              <Stack
                direction={{ base: "column", xl: "row" }}
                width="100%"
                bgColor={index > 0 ? "#F7FAFC" : "white"}
                borderRadius="md"
                spacing={4}
                px="2"
                py="1"
                key={field.id}
                mt="2"
              >
                <Box w={{ base: "100%", md: "100%", xl: "2%" }}>
                  <FormControl>
                    {index === 0 && (
                      <FormLabel
                        fontSize={{
                          base: "10",
                          md: "10",
                          lg: "10",
                          xl: "10",
                          "2xl": "12"
                        }}
                      >
                        ID
                      </FormLabel>
                    )}

                    {fields.length - index}
                  </FormControl>
                </Box>
                <Box w={{ base: "100%", md: "100%", xl: "35%" }}>
                  <Controller
                    control={control}
                    name={`stockOperationItems.${currentIndex}.product`}
                    rules={{ required: "Select product" }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { error }
                    }) => (
                      <FormControl
                        isInvalid={!!error}
                        id={`stockOperationItems.${currentIndex}.product`}
                        fontSize={{
                          base: "10",
                          md: "10",
                          lg: "10",
                          xl: "10",
                          "2xl": "12"
                        }}
                      >
                        {index === 0 && (
                          <FormLabel
                            fontSize={{
                              base: "10",
                              md: "10",
                              lg: "10",
                              xl: "10",
                              "2xl": "12"
                            }}
                          >
                            Select Product
                          </FormLabel>
                        )}
                        <Select<ProductGroup, true, GroupBase<ProductGroup>>
                          name={name}
                          ref={ref}
                          onChange={(value) => {
                            handleProductSelect(onChange, value, currentIndex);
                          }}
                          onBlur={onBlur}
                          value={value}
                          options={RemoveSelectedProductFromAllProducts(
                            productOptions,
                            watch
                          )}
                          placeholder="Select product"
                          closeMenuOnSelect={true}
                          size="md"
                        />
                        <FormErrorMessage>
                          {error && error.message}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                </Box>
                <Box w={{ base: "100%", md: "100%", xl: "10%" }}>
                  <FormControl>
                    {index === 0 && (
                      <FormLabel
                        fontSize={{
                          base: "10",
                          md: "10",
                          lg: "10",
                          xl: "10",
                          "2xl": "12"
                        }}
                      >
                        Unit
                      </FormLabel>
                    )}
                    <Input
                      _disabled={{
                        color: "blackAlpha.900",
                        cursor: "not-allowed"
                      }}
                      defaultValue={watch[currentIndex]?.product?.unit}
                      disabled={true}
                      placeholder="unit"
                      fontSize={{
                        base: "10",
                        md: "10",
                        lg: "10",
                        xl: "10",
                        "2xl": "12"
                      }}
                    />
                  </FormControl>
                </Box>
                <Box w={{ base: "100%", md: "100%", xl: "8%" }}>
                  <FormControl>
                    {index === 0 && (
                      <FormLabel
                        fontSize={{
                          base: "10",
                          md: "10",
                          lg: "10",
                          xl: "10",
                          "2xl": "12"
                        }}
                      >
                        Available
                      </FormLabel>
                    )}
                    <Input
                      _disabled={{
                        color: "blackAlpha.900",
                        cursor: "not-allowed"
                      }}
                      defaultValue={watch[currentIndex]?.product?.quantity}
                      disabled={true}
                      placeholder="Available"
                      fontSize={{
                        base: "10",
                        md: "10",
                        lg: "10",
                        xl: "10",
                        "2xl": "12"
                      }}
                    />
                  </FormControl>
                </Box>
                <Box w={{ base: "100%", md: "100%", xl: "12%" }}>
                  <FormControl
                    isDisabled={
                      watch[currentIndex]?.product?.type !==
                        ProductTrackingTypeOptions.SERIALIZED &&
                      watch[currentIndex]?.product?.type !==
                        ProductTrackingTypeOptions.BATCH
                    }
                    isInvalid={
                      errors?.stockOperationItems?.[currentIndex]
                        ?.trackingNumber
                        ? true
                        : false
                    }
                  >
                    {index === 0 && (
                      <FormLabel
                        fontSize={{
                          base: "10",
                          md: "10",
                          lg: "10",
                          xl: "10",
                          "2xl": "12"
                        }}
                      >
                        Tracking Number
                      </FormLabel>
                    )}
                    <Input
                      {...register(
                        `stockOperationItems.${Number(
                          currentIndex
                        )}.trackingNumber` as const,
                        {
                          validate: {
                            required: (value) => {
                              if (
                                watch[currentIndex]?.product?.type ===
                                  ProductTrackingTypeOptions.NONE ||
                                value !== ""
                              )
                                return true;
                              return "Give Tracking data";
                            }
                          }
                        }
                      )}
                      key={`tracking${currentIndex}`}
                      type={"string"}
                      placeholder="Tracking Number"
                      onKeyDown={(e) =>
                        handleKeyDown(e, currentIndex, "trackingNumber")
                      }
                      fontSize={{
                        base: "10",
                        md: "10",
                        lg: "10",
                        xl: "10",
                        "2xl": "12"
                      }}
                    />
                    <FormErrorMessage>
                      {
                        errors?.stockOperationItems?.[currentIndex]
                          ?.trackingNumber?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                </Box>
                <Box w={{ base: "100%", md: "100%", xl: "12%" }}>
                  <FormControl>
                    {index === 0 && (
                      <FormLabel
                        fontSize={{
                          base: "10",
                          md: "10",
                          lg: "10",
                          xl: "10",
                          "2xl": "12"
                        }}
                      >
                        Estimated Balance
                      </FormLabel>
                    )}
                    <Input
                      _disabled={{
                        color: "blackAlpha.900",
                        cursor: "not-allowed"
                      }}
                      fontSize={{
                        base: "10",
                        md: "10",
                        lg: "10",
                        xl: "10",
                        "2xl": "12"
                      }}
                      value={
                        watch[currentIndex]?.product &&
                        watch[currentIndex]?.product.type &&
                        watch[currentIndex]?.product.type ===
                          ProductTrackingTypeOptions.SERIALIZED
                          ? Number(watch[currentIndex]?.product?.quantity) - 1
                          : Number(watch[currentIndex]?.product?.quantity) -
                            Number(watch[currentIndex]?.quantity)
                      }
                      disabled={true}
                      placeholder="Available"
                    />
                  </FormControl>
                </Box>
                <Box w={{ base: "100%", md: "100%", xl: "13%" }}>
                  <FormControl
                    mb="3"
                    isInvalid={
                      errors?.stockOperationItems?.[currentIndex]?.quantity
                        ? true
                        : false
                    }
                  >
                    {index === 0 && (
                      <FormLabel
                        fontSize={{
                          base: "10",
                          md: "10",
                          lg: "10",
                          xl: "10",
                          "2xl": "12"
                        }}
                      >
                        Quantity
                      </FormLabel>
                    )}
                    <Input
                      {...register(
                        `stockOperationItems.${currentIndex}.quantity` as const,
                        {
                          validate: {
                            required: (value) => {
                              if (
                                watch[currentIndex]?.product?.type ===
                                ProductTrackingTypeOptions.SERIALIZED
                              )
                                return true;
                              if (
                                value &&
                                value > 0 &&
                                value <=
                                  Number(watch[currentIndex]?.product?.quantity)
                              )
                                return true;
                              return "Give Valid Amount";
                            }
                          }
                        }
                      )}
                      onKeyDown={(e) =>
                        handleKeyDown(e, currentIndex, "quantity")
                      }
                      key={`quantity${currentIndex}`}
                      disabled={
                        watch[currentIndex]?.product?.type !==
                          ProductTrackingTypeOptions.NONE &&
                        watch[currentIndex]?.product?.type !==
                          ProductTrackingTypeOptions.BATCH
                      }
                      defaultValue={getQuantity(
                        watch[currentIndex]?.product?.type
                      )}
                      placeholder="Enter product quantity"
                      fontSize={{
                        base: "10",
                        md: "10",
                        lg: "10",
                        xl: "10",
                        "2xl": "12"
                      }}
                      type={"number"}
                    />
                    <FormErrorMessage>
                      {
                        errors?.stockOperationItems?.[currentIndex]?.quantity
                          ?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                </Box>
                <Box w={{ base: "100%", md: "100%", xl: "8%" }}>
                  <FormControl mb="3">
                    <ButtonGroup>
                      <Button
                        mt={index === 0 ? 8 : 1}
                        colorScheme="red"
                        variant="outline"
                        leftIcon={<FaTimes />}
                        onClick={() => remove(currentIndex)}
                        fontSize={{
                          base: "10",
                          md: "10",
                          lg: "10",
                          xl: "10",
                          "2xl": "12"
                        }}
                        size="sm"
                      >
                        Remove
                      </Button>
                    </ButtonGroup>
                  </FormControl>
                </Box>
              </Stack>
            );
          })}
        <Stack spacing={4}>
          <Textarea
            placeholder="Enter notes for this Operation"
            {...register("notes")}
          />

          <Button
            width="100%"
            colorScheme="blue"
            onClick={transferConfirmAlert.onOpen}
          >
            Transfer
          </Button>
          <ConfirmAlertComponent
            cancelRef={cancelRef}
            onClose={transferConfirmAlert.onClose}
            isOpen={transferConfirmAlert.isOpen}
            onOpen={transferConfirmAlert.onOpen}
            handleApprove={transferHandleSubmit}
            status={createTransferResult}
          />
        </Stack>
      </form>
      <Stack display="flex" alignContent="end" direction="row" mt="10px">
        <FormControl display="flex" alignItems="end">
          <FormLabel htmlFor="email-alerts" mb="0">
            Easy Add
          </FormLabel>
          <Switch
            id="email-alerts"
            isChecked={isNewAddOn}
            onChange={(e) => setIsNewAddOn((pre) => !pre)}
          />
        </FormControl>
      </Stack>
    </Stack>
  );
};
export default CmchTransferComponent;
