import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Td,
  Th,
  Tr,
  Table,
  useToast,
  Flex
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { useOperationItemEditMutation } from "../../../api";
import { ProductTrackingTypeOptions } from "../../../api/type";
import { PageCardComponent } from "../../core/components/page-card.component";

interface OperationItemEditModalComponentProps {
  cancelRef: any;
  isOpen: any;
  onOpen: any;
  onClose: any;
  selectedItem: {
    operationId: number;
    operationType: string;
    operationDate: string;
    originLocationName: string;
    destinationLocationName?: string;
    itemId: number;
    itemName: string;
    trackingNumber?: string;
    currentQuantity: number;
    status: string;
    itemType: string;
  };
}

export const OperationItemEditModalComponent: React.FC<
  OperationItemEditModalComponentProps
> = (props: OperationItemEditModalComponentProps) => {
  const toast = useToast();
  const [newQuantity, setNewQuantity] = useState<number>();

  const [operationItemEditRequest, operationItemEditResult] =
    useOperationItemEditMutation();

  const formSubmit = () => {
    operationItemEditRequest({
      data: {
        operationId: props.selectedItem.operationId,
        itemId: props.selectedItem.itemId,
        updatedQuantity: newQuantity
          ? Number(newQuantity)
          : props.selectedItem.currentQuantity
      }
    });
    props.onClose();
  };

  const changeNewQuantity = (e: any) => {
    if (props.selectedItem.itemType === ProductTrackingTypeOptions.SERIALIZED) {
      if (Number(e.target.value) > 0) {
        setNewQuantity(1);
      } else {
        setNewQuantity(0);
      }
    } else {
      if (Number(e.target.value) >= 0) {
        setNewQuantity(e.target.value);
      } else {
        setNewQuantity(undefined);
      }
    }
  };

  useEffect(() => {
    if (operationItemEditResult.isSuccess) {
      toast({
        title: "Success",
        description: "Item quantity  updated  successfully .",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });
    }
  }, [operationItemEditResult]);

  return (
    <Stack>
      <Modal
        motionPreset="slideInBottom"
        onClose={props.onClose}
        isOpen={props.isOpen}
        size="6xl"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="red.500" fontSize="lg">
            Update Operation Item Quantity (Warning**)
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Stack spacing={8} direction={{ base: "column", lg: "row" }}>
              <PageCardComponent>
                <Stack>
                  <Table size={"sm"} variant={"unstyled"}>
                    <Tr>
                      <Th>Origin Location</Th>
                      <Td>:</Td>
                      <Td>{props.selectedItem.originLocationName}</Td>
                    </Tr>
                    <Tr>
                      <Th>Operation Type</Th>
                      <Td>:</Td>
                      <Td>{props.selectedItem.operationType}</Td>
                    </Tr>
                    <Tr>
                      <Th>Operation Status</Th>
                      <Td>:</Td>
                      <Td>{props.selectedItem.status}</Td>
                    </Tr>
                  </Table>
                </Stack>
              </PageCardComponent>
              <PageCardComponent>
                <Stack>
                  <Table size={"sm"} variant={"unstyled"}>
                    <Tr>
                      <Th>Destination Location</Th>
                      <Td>:</Td>
                      <Td>{props.selectedItem.destinationLocationName}</Td>
                    </Tr>
                    <Tr>
                      <Th>Date</Th>
                      <Td>:</Td>
                      <Td>
                        {" "}
                        {dayjs(props.selectedItem.operationDate).format(
                          "ddd, MMM D, YYYY h:mm A"
                        )}
                      </Td>
                    </Tr>
                    <Tr>
                      <Th>Operation ID</Th>
                      <Td>:</Td>
                      <Td fontWeight={700}>{props.selectedItem.operationId}</Td>
                    </Tr>
                  </Table>
                </Stack>
              </PageCardComponent>
            </Stack>
            <Stack>
              <FormControl>
                <FormLabel>Product Name</FormLabel>
                <Input value={props.selectedItem.itemName} isDisabled />
              </FormControl>
              <Flex>
                <FormControl>
                  <FormLabel>Tracking Type</FormLabel>
                  <Input value={props.selectedItem.itemType} isDisabled />
                </FormControl>
                {props.selectedItem.itemType !==
                  ProductTrackingTypeOptions.SERIALIZED && (
                  <FormControl>
                    <FormLabel>TrackingNumber</FormLabel>
                    <Input
                      value={props.selectedItem.trackingNumber}
                      isDisabled
                    />
                  </FormControl>
                )}
              </Flex>
              <Flex>
                <FormControl>
                  <FormLabel>Current Quantity</FormLabel>
                  <Input
                    value={props.selectedItem.currentQuantity}
                    isDisabled
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>New Quantity</FormLabel>
                  <Input
                    value={newQuantity}
                    type="number"
                    onChange={(e) => changeNewQuantity(e)}
                  />
                </FormControl>
              </Flex>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button onClick={props.onClose} colorScheme="red">
              Cancel
            </Button>
            <Button colorScheme="green" ml="3" onClick={() => formSubmit()}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};
