import React, { useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  Spacer,
  FormControl,
  FormLabel,
  Input,
  useDisclosure
} from "@chakra-ui/react";
import { PageCardComponent } from "../../core/components/page-card.component";
import { useLazyGetAllOperationDetailsQuery } from "../../../api";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { IoMdArrowRoundBack } from "react-icons/io";
import { userCurrentRole } from "../../../utils/common-functions";

import { OperationItemEditModalComponent } from "./operation-item-edit-modal.component";
import { FaEdit } from "react-icons/fa";
interface OperationItemEditComponentProps {}

export const OperationItemEditComponent: React.FC<
  OperationItemEditComponentProps
> = (props: OperationItemEditComponentProps) => {
  const [selectedItem, setSelectedItem] = useState<{
    operationId: number;
    operationType: string;
    operationDate: string;
    originLocationName: string;
    destinationLocationName?: string;
    status: string;
    itemId: number;
    itemName: string;
    trackingNumber?: string;
    currentQuantity: number;
    itemType: string;
  }>();
  const cancelRef = React.useRef();
  const operationItemEditModal = useDisclosure();
  const navigate = useNavigate();
  const role = userCurrentRole();
  const [operationId, setOperationId] = useState<number>();

  const [getOperationDetails, operationDetails] =
    useLazyGetAllOperationDetailsQuery();

  const goBack = () => {
    navigate(-1);
  };
  const findOperation = () => {
    if (operationId && operationId > 0) {
      getOperationDetails({
        data: { report: { operationId: Number(operationId) } }
      });
    }
  };
  const editItemQuantityModalOpen = (item: {
    itemId: number;
    itemName: string;
    itemType: string;
    trackingNumber?: string;
    currentQuantity: number;
  }) => {
    if (operationDetails.data?.data.operationDetails) {
      const operation = operationDetails.data?.data.operationDetails;
      const destinationName = operation.destinationLocation
        ? operation.destinationLocation.name
        : "";
      const newSelectedItem = {
        operationId: operation.id,
        operationType: operation.operationType,
        operationDate: operation.createdAt,
        originLocationName: operation.originLocation.name || "",
        status: operation.operationStatus,
        destinationLocationName: destinationName,
        itemId: item.itemId,
        itemName: item.itemName,
        itemType: item.itemType,
        trackingNumber: item.trackingNumber ? item.trackingNumber : "",
        currentQuantity: item.currentQuantity
      };
      operationItemEditModal.onOpen();

      setSelectedItem(newSelectedItem);
    }
  };
  return (
    <Stack>
      {selectedItem && (
        <OperationItemEditModalComponent
          cancelRef={cancelRef}
          onClose={operationItemEditModal.onClose}
          isOpen={operationItemEditModal.isOpen}
          onOpen={operationItemEditModal.onOpen}
          selectedItem={selectedItem}
          key={selectedItem.itemId}
        />
      )}
      <Stack
        direction={{ base: "column", xl: "row" }}
        justify="space-between"
        shadow="base"
        bg="white"
        padding={5}
      >
        <Box w={{ base: "100%", md: "100%", xl: "50%" }}>
          <FormControl>
            <FormLabel
              fontSize={{
                base: "10",
                md: "10",
                lg: "10",
                xl: "10",
                "2xl": "12"
              }}
            >
              Give operation Id
            </FormLabel>

            <Input
              placeholder="Enter 3 or 4 letters to find"
              value={operationId}
              type="number"
              autoFocus={operationId ? true : false}
              onChange={(e) => setOperationId(Number(e.target.value))}
            />
          </FormControl>
        </Box>

        <Spacer />
        <Box w={{ base: "100%", md: "100%", xl: "10%" }}>
          <FormControl>
            <Button
              colorScheme="blue"
              fontSize={{
                base: "10",
                md: "10",
                lg: "10",
                xl: "10",
                "2xl": "12"
              }}
              mt={6}
              onClick={() => findOperation()}
            >
              Search{" "}
            </Button>
          </FormControl>
        </Box>
      </Stack>
      <Flex justifyContent="end">
        <Button
          colorScheme="blue"
          leftIcon={<IoMdArrowRoundBack />}
          onClick={() => goBack()}
        >
          Back
        </Button>
      </Flex>
      {operationDetails.data?.data.operationDetails.id && (
        <Stack>
          <Stack spacing={8} direction={{ base: "column", lg: "row" }}>
            <PageCardComponent>
              <Stack>
                <Table size={"sm"} variant={"unstyled"}>
                  <Tr>
                    <Th>Origin Location</Th>
                    <Td>:</Td>
                    <Td>
                      {
                        operationDetails.data?.data.operationDetails
                          .originLocation?.name
                      }
                    </Td>
                  </Tr>
                  <Tr>
                    <Th>Operation Type</Th>
                    <Td>:</Td>
                    <Td>
                      {
                        operationDetails.data?.data.operationDetails
                          .operationType
                      }
                    </Td>
                  </Tr>
                  <Tr>
                    <Th>Operation Status</Th>
                    <Td>:</Td>
                    <Td>
                      {
                        operationDetails.data?.data.operationDetails
                          .operationStatus
                      }
                    </Td>
                  </Tr>
                  <Tr>
                    <Th>Operation By</Th>
                    <Td>:</Td>
                    <Td>
                      {
                        operationDetails.data?.data.operationDetails.createdBy
                          ?.name
                      }
                    </Td>
                  </Tr>
                  <Tr>
                    <Th>Phone</Th>
                    <Td>:</Td>
                    <Td>
                      {
                        operationDetails.data?.data.operationDetails.createdBy
                          ?.contact
                      }
                    </Td>
                  </Tr>
                </Table>
              </Stack>
            </PageCardComponent>

            <PageCardComponent>
              <Stack>
                <Table size={"sm"} variant={"unstyled"}>
                  <Tr>
                    <Th>Destination Location</Th>
                    <Td>:</Td>
                    <Td>
                      {
                        operationDetails.data?.data.operationDetails
                          ?.destinationLocation?.name
                      }
                    </Td>
                  </Tr>

                  <Tr>
                    <Th>Date</Th>
                    <Td>:</Td>
                    <Td>
                      {" "}
                      {dayjs(
                        operationDetails.data?.data.operationDetails.createdAt
                      ).format("ddd, MMM D, YYYY h:mm A")}
                    </Td>
                  </Tr>
                  <Tr>
                    <Th>Notes</Th>
                    <Td>:</Td>
                    <Td>
                      {operationDetails.data?.data.operationDetails.notes}
                    </Td>
                  </Tr>
                  <Tr>
                    <Th>Operation ID</Th>
                    <Td>:</Td>
                    <Td fontWeight={700}>
                      {operationDetails.data?.data.operationDetails.id}
                    </Td>
                  </Tr>
                  <Tr>
                    <Th> Invoice Number</Th>
                    <Td>:</Td>
                    <Td fontWeight={700}>
                      {operationDetails.data?.data.operationDetails.invoiceNo}
                    </Td>
                  </Tr>
                </Table>
              </Stack>
            </PageCardComponent>
          </Stack>
          {operationDetails.data?.data.operationDetails.prescription && (
            <Stack>
              <PageCardComponent>
                <Flex alignItems="center">
                  <Box>
                    <Heading as="h1" size="sm">
                      Distribution To
                    </Heading>
                  </Box>
                  <Spacer />
                </Flex>
                <Stack>
                  <Table size={"sm"} variant={"unstyled"}>
                    <Tr>
                      <Th>Prescription ID</Th>
                      <Td>:</Td>
                      <Td>
                        {
                          operationDetails.data?.data.operationDetails
                            .prescription.id
                        }
                      </Td>
                    </Tr>
                    <Tr>
                      <Th>Type</Th>
                      <Td>:</Td>
                      <Td>
                        {
                          operationDetails.data?.data.operationDetails
                            .prescription.type
                        }
                      </Td>
                    </Tr>
                    <Tr>
                      <Th>Patient Name</Th>
                      <Td>:</Td>
                      <Td>
                        {
                          operationDetails.data?.data.operationDetails
                            .prescription.patient?.name
                        }
                      </Td>
                    </Tr>
                    <Tr>
                      <Th>Contact Number</Th>
                      <Td>:</Td>
                      <Td>
                        {
                          operationDetails.data?.data.operationDetails
                            .prescription.patient?.contactNumber
                        }
                      </Td>
                    </Tr>
                  </Table>
                </Stack>
              </PageCardComponent>
            </Stack>
          )}
          <PageCardComponent>
            <Flex alignItems="center">
              <Box>
                <Heading as="h1" size="sm">
                  Operation Summary
                </Heading>
              </Box>
            </Flex>
            <TableContainer>
              <Table variant="striped">
                <Thead>
                  <Tr bg="gray.700">
                    <Th color="white">ID</Th>
                    <Th color="white">Item ID</Th>
                    <Th color="white">Product Name</Th>
                    <Th color="white">Tracking Number</Th>
                    <Th color="white">Total Amount</Th>
                    <Th color="white">Unit</Th>
                    <Th color="white">Edit Item</Th>
                  </Tr>
                </Thead>
                {operationDetails.data?.data.operationDetails && (
                  <Tbody>
                    {operationDetails.data?.data?.operationDetails?.stockOperationItems?.map(
                      (data, key) => (
                        <Tr key={key}>
                          <Td>{key + 1}</Td>
                          <Td>{data.id}</Td>
                          <Td>{data.product.name}</Td>
                          <Td>{data?.trackingNumber}</Td>

                          <Td>{data.quantity}</Td>
                          <Td>{data.product.unit}</Td>
                          <Td
                            onClick={() =>
                              editItemQuantityModalOpen({
                                itemId: data.id,
                                itemName: data.product.name,
                                itemType: data.product.trackingType,
                                trackingNumber: data.trackingNumber,
                                currentQuantity: data.quantity
                              })
                            }
                          >
                            <Button
                              leftIcon={<FaEdit />}
                              size={"sm"}
                              color="messenger.400"
                              colorScheme={"messenger"}
                              variant={"outline"}
                            >
                              Edit
                            </Button>
                          </Td>
                        </Tr>
                      )
                    )}
                  </Tbody>
                )}
              </Table>
            </TableContainer>
          </PageCardComponent>
        </Stack>
      )}
    </Stack>
  );
};
